/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Layout from "../components/Layout";
const MDXLayout = Layout;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components), {Cgv} = _components;
  if (!Cgv) _missingMdxReference("Cgv", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Mentions Légales"), "\n", React.createElement(_components.h2, null, "Informations générales"), "\n", React.createElement(_components.p, null, "Ce site est édité par la société Laboratoires Industriels G. Pichot, Société par\nactions simplifiée au capital de 195 891,00 €, immatriculée au Registre du\nCommerce et des Sociétés de Clermont-Ferrand sous le numéro 350 046 876 et dont\nle siège social se situe Lieu-dit Bourchany, 63880 Le Brugeron."), "\n", React.createElement(_components.p, null, "Numéro d'identification à la TVA : FR16 350046876"), "\n", React.createElement(_components.p, null, "La société Laboratoires Industriels G. Pichot peut être contactée par courriel à l’adresse suivante :\ncontact@labopichot.com, et au numéro de téléphone suivant: 04 73 72 63 06."), "\n", React.createElement(_components.h2, null, "Conditions Générales de Ventes"), "\n", React.createElement(_components.p, null, "Nous vous invitons à prendre connaissance de nos ", React.createElement(Cgv)), "\n", React.createElement(_components.h2, null, "Traitement des données à caractère personnel"), "\n", React.createElement(_components.p, null, "Aucune donnée personnelle n'est collectée sur ce site."), "\n", React.createElement(_components.h2, null, "Hébergeur du site internet"), "\n", React.createElement(_components.p, null, "Le site est hébergé par Netlify Inc., dont le siège social est situé 2325 3rd Street, Suite 296, San Francisco, California 94107."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
